import React from "react"
import { graphql } from "gatsby"
import Img from "gatsby-image/withIEPolyfill"

import Layout from "../components/layout"
import SEO from "../components/seo"
import PageSection from "../components/page-section"
import Figure from "../components/figure"

const PaintingPage = props => (
  <Layout>
    <SEO title="Painting" />

    <PageSection content={{ heading: 'Painting' }} >
      <Figure content={{ figcaption: 'Untitled, undated, 165cm x 165cm, acrylic on canvas' }} options={{ lightOnDark: true }} >
        <Img fluid={props.data.imagePnt045.childImageSharp.fluid} alt="Painting nr. 045: Untitled" />
      </Figure>
      <Figure content={{ figcaption: 'Untitled, 1998, 150cm x 150cm, acrylic on canvas' }} options={{ lightOnDark: true }} >
        <Img fluid={props.data.imagePnt058.childImageSharp.fluid} alt="Painting nr. 058: Untitled" />
      </Figure>
      <Figure content={{ figcaption: 'Untitled, undated, 160cm x 160cm, acrylic on canvas' }} options={{ lightOnDark: true }} >
        <Img fluid={props.data.imagePnt071.childImageSharp.fluid} alt="Painting nr. 071: Untitled" />
      </Figure>
      <Figure content={{ figcaption: 'Untitled, undated, 180cm x 180cm, acrylic on canvas' }} options={{ lightOnDark: true }} >
        <Img fluid={props.data.imagePnt072.childImageSharp.fluid} alt="Painting nr. 072: Untitled" />
      </Figure>
      <Figure content={{ figcaption: 'Untitled, undated, 135cm x 135cm, acrylic on canvas' }} options={{ lightOnDark: true }} >
        <Img fluid={props.data.imagePnt046.childImageSharp.fluid} alt="Painting nr. 046: Untitled" />
      </Figure>
      <Figure content={{ figcaption: 'Untitled, 2012, 50cm x 60cm, acrylic on canvas' }} options={{ lightOnDark: true, sizeS: true }} >
        <Img fluid={props.data.imagePnt090.childImageSharp.fluid} alt="Painting nr. 090: Untitled" />
      </Figure>
      <Figure content={{ figcaption: 'Untitled, 2012, 170cm x 125cm, acrylic on canvas' }} options={{ lightOnDark: true }} >
        <Img fluid={props.data.imagePnt073.childImageSharp.fluid} alt="Painting nr. 073: Untitled" />
      </Figure>
      <Figure content={{ figcaption: 'Untitled, undated, 100cm x 100cm, acrylic on canvas' }} options={{ lightOnDark: true }} >
        <Img fluid={props.data.imagePnt077.childImageSharp.fluid} alt="Painting nr. 077: Untitled" />
      </Figure>
    </PageSection>

  </Layout>
)

export default PaintingPage

export const fluidImage = graphql`
  fragment fluidImage on File {
    childImageSharp {
      fluid(maxWidth: 768) {
        ...GatsbyImageSharpFluid_withWebp
      }
    }
  }
`;

export const pageQuery = graphql`
  query {
    imagePnt045: file(relativePath: { eq: "painting-045.jpg" }) {
      ...fluidImage
    }
    imagePnt046: file(relativePath: { eq: "painting-046.jpg" }) {
      ...fluidImage
    }
    imagePnt058: file(relativePath: { eq: "painting-058.jpg" }) {
      ...fluidImage
    }
    imagePnt071: file(relativePath: { eq: "painting-071.jpg" }) {
      ...fluidImage
    }
    imagePnt072: file(relativePath: { eq: "painting-072.jpg" }) {
      ...fluidImage
    }
    imagePnt073: file(relativePath: { eq: "painting-073.jpg" }) {
      ...fluidImage
    }
    imagePnt077: file(relativePath: { eq: "painting-077.jpg" }) {
      ...fluidImage
    }
    imagePnt090: file(relativePath: { eq: "painting-090.jpg" }) {
      ...fluidImage
    }
  }
`;